import * as React from 'react'
import styled from '@emotion/styled'
import { useNavLinks } from '../../side-navigation/hooks/useNavLinks'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { useAnnouncementBanner_LEGACY } from '../hooks/useAnnouncementBanner_LEGACY'
import { useSetAnnouncementBanner } from '../hooks/useAnnouncementBanner'
import { transientOptions } from '../../../common/style/transientOptions'
import { useHideHeaderOnScroll } from '../hooks/useHideHeaderOnScroll'
import { pathsWithoutHeader, patternsWithoutHeader } from '../pathsWithoutHeader'
import dynamic from 'next/dynamic'
import { useLoadDevToolsNavIndicators } from '../../admin/dev-tools/hooks/useLoadDevToolsNavIndicators'
import { usePathname } from 'next/navigation'
import { useFranchiseDeploymentData } from '../../account/features/franchise-membership/hooks/useHost/useFranchiseDeploymentData'
import { useReconstructionHandler } from '../../reconstruction/hooks/useReconstructionHandler'

const LazyAuthenticatedReconstructionBanner = dynamic(() =>
  import('../../reconstruction/atoms/EagerAuthenticatedReconstructionBanner').then(
    (x) => x.EagerAuthenticatedReconstructionBanner
  )
)

const LazyAxoNavContent = dynamic(() => import('./AxoNavContent').then((x) => x.AxoNavContent))
const LazyFranchiseNavContent = dynamic(() =>
  import('./FranchiseNavContent/EagerFranchiseNavContent').then((x) => x.EagerFranchiseNavContent)
)

const LazyDevToolsNavIndicators = dynamic(() =>
  import('../../admin/dev-tools/organisms/DevToolsNavIndicators').then((x) => x.DevToolsNavIndicators)
)

const LazySubnav = dynamic(() => import('../molecules/Subnav').then((x) => x.Subnav))

const Container = styled('nav', transientOptions)<{ $hideHeader: boolean }>`
  transform: translateY(${(p) => (p.$hideHeader ? -100 : 0)}%);
  transition: transform 0.3s ease-out;

  pointer-events: auto;
  --nav-side-padding: 64px;

  ${tabletDownMq} {
    --nav-side-padding: 8px;
  }
`

export const navbarId = 'navbar'

export function Navbar() {
  const AnnouncementBanner_LEGACY = useAnnouncementBanner_LEGACY()

  const {
    AnnouncementBanner,
    jotai: [{ hideHeaderOnScroll }],
  } = useSetAnnouncementBanner()
  const { showLimitedUi } = useReconstructionHandler()

  const bannerJsx = showLimitedUi ? (
    <LazyAuthenticatedReconstructionBanner />
  ) : AnnouncementBanner_LEGACY ? (
    <AnnouncementBanner_LEGACY />
  ) : AnnouncementBanner ? (
    <AnnouncementBanner />
  ) : null

  const pathname = usePathname() ?? ''

  const { hideHeader } = useHideHeaderOnScroll()

  const { subnav } = useNavLinks()

  const loadDevToolsNavIndicators = useLoadDevToolsNavIndicators()
  const { isFranchiseUi } = useFranchiseDeploymentData()

  if (pathsWithoutHeader.has(pathname) || patternsWithoutHeader.some((x) => x.test(pathname))) {
    return null
  }

  return (
    <Container id={navbarId} $hideHeader={!!hideHeaderOnScroll && hideHeader}>
      {loadDevToolsNavIndicators && <LazyDevToolsNavIndicators />}
      {isFranchiseUi ? <LazyFranchiseNavContent /> : <LazyAxoNavContent />}
      {subnav.length > 1 && <LazySubnav subnav={subnav} />}
      {bannerJsx}
    </Container>
  )
}
