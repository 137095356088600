import * as React from 'react'
import { useRouter } from 'next/navigation'
import { UseReconstructionHandlerReturn } from './useReconstructionHandler'
import { homeRoute } from '../../../common/constants/routes'
import { useInterval } from '../../../hooks/useInterval'

export const useRedirectReconstructionPages = ({ redirectToHome }: UseReconstructionHandlerReturn) => {
  const { replace } = useRouter()

  React.useEffect(() => {
    if (redirectToHome) {
      replace(homeRoute)
    }
  }, [redirectToHome, replace])

  useInterval(() => {
    if (redirectToHome) {
      replace(homeRoute)
    }
  }, 300)
}
