import styled from '@emotion/styled'
import React from 'react'
// import { LazyRouteChangeProgressBar } from '../atoms/LazyRouteChangeProgressBar'
import { useModalState } from '../../modal/modalState'
import { visibleScreenHeightVar } from '../../../styles/visibleScreenHeightVarName'
// import { useLazyFeedbackDial } from '../../help/hooks/useLazyFeedbackDial'
import { useSwitchToAirplaneModeOnNetworkLoss } from '../../admin/dev-tools/hooks/useSwitchToAirplaneModeOnNetworkLoss'
import { useDimensions } from '../../../hooks/useDimensions'
import { defaultAppHeaderHeight, theme } from '../../../styles/theme'
import { writeHeaderHeightToCssVar } from '../helpers/writeHeaderHeightToCssVar'
import { useAppLoadingSpinnerJsx } from '../hooks/useAppLoadingSpinnerJsx'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useReconstructionHandler } from '../../reconstruction/hooks/useReconstructionHandler'
import dynamic from 'next/dynamic'
import { useRedirectReconstructionPages } from '../../reconstruction/hooks/useRedirectReconstructionPages'

const LazyUnderConstructionPage = dynamic(() =>
  import('../../reconstruction/pages/UnderConstructionPage').then((x) => x.UnderConstructionPage)
)

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 0;
  display: relative;
  min-height: ${visibleScreenHeightVar};
`

const Body = styled.main`
  flex: 1;
  z-index: 0;
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  max-width: 100vw;
`

const Footer = styled.footer`
  width: 100%;
`

/* 
ORDER: (toast is top level but is handled in separate provider in _app.tsx via 3rd party library)
  1. Modal
    1.5 Modal Overlay
  2. Sidebar -> 
    2.5 Sidebar Overlay
  3: App Header
  4: App Body andd Footer
*/

interface Props {
  header: React.ReactElement
  footer?: React.ReactNode
  body: React.ReactNode
  modal: React.ReactNode
  sidebar: React.ReactNode
}

const Sidebar = styled.div`
  z-index: 2;
  position: relative;
`

const Modal = styled.div`
  position: relative;
  z-index: 3;
`

// const FeedbackDialElement = styled.div`
//   position: fixed;
//   right: ${theme.spacing.feedbackDialRight};
//   bottom: ${theme.spacing.feedbackDialBottom};
//   z-index: 1;
//   transition: bottom 0.5s;
// `

export const appHeaderId = 'app-header'
export const appSidebarId = 'app-sidebar'
export const appModalId = 'app-modal'
export const appBodyId = 'app-body'
export const appFooterId = 'app-footer'
export const appFeedbackFormId = 'app-feedback'

const HeaderFiller = styled.div`
  background-color: ${theme.colors.grayscale000};
`

const useAppHeaderHeight = () => {
  const appHeaderRef = React.useRef<HTMLHeadingElement>(null)

  const appHeaderHeight = useDimensions(appHeaderRef).height
  const isMounted = useIsMounted()
  const finalHeight = isMounted ? appHeaderHeight : appHeaderHeight || defaultAppHeaderHeight
  if (isMounted) writeHeaderHeightToCssVar(finalHeight)
  return { appHeaderRef, appHeaderHeight: finalHeight }
}

export function AppLayoutTemplate({ header, body, footer, modal, sidebar }: Props) {
  // const LazyFeedbackDial = useLazyFeedbackDial()

  const [{ isFullscreenGradientTakeover }] = useModalState()

  useSwitchToAirplaneModeOnNetworkLoss()

  const appLoadingSpinnerJsx = useAppLoadingSpinnerJsx()

  const { appHeaderHeight, appHeaderRef } = useAppHeaderHeight()

  const useReconstructionHandlerReturn = useReconstructionHandler()

  useRedirectReconstructionPages(useReconstructionHandlerReturn)

  const { showLoadingUi, showUnderConstructionUi } = useReconstructionHandlerReturn
  return (
    <>
      <Modal id={appModalId}>{modal}</Modal>
      <Sidebar id={appSidebarId}>{sidebar}</Sidebar>
      <span style={{ display: isFullscreenGradientTakeover ? 'none' : 'initial' }}>
        <MainContent>
          <Header id={appHeaderId} role="banner" ref={appHeaderRef}>
            {header}
          </Header>
          <HeaderFiller style={{ height: appHeaderHeight }} />
          <Body id={appBodyId} role="main">
            {appLoadingSpinnerJsx ?? (showLoadingUi || showUnderConstructionUi ? <LazyUnderConstructionPage /> : body)}
          </Body>
        </MainContent>
        <Footer id={appFooterId} role="contentinfo">
          {footer}
        </Footer>
        {/* {!!LazyFeedbackDial && (
          <FeedbackDialElement id={appFeedbackFormId}>
            <LazyFeedbackDial />
          </FeedbackDialElement>
        )} */}
      </span>
    </>
  )
}
